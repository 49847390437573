import { RemotePlayer } from "./RemotePlayer";
import { StringVariableHelper } from "./StringVariableHelper";

export class RemotePlayerHandler {
    private roomID: string;

    public OnInitialAvatarPositionsUpdated: NSM.Delegate = new NSM.Delegate();
    public OnPlayersInitialized: NSM.Delegate = new NSM.Delegate();

    private remotePlayerMap: Map<string, RemotePlayer> = new Map();

    private elapsedTimeSinceLastTransformUpdateRequest: number = 0;
    private transformUpdateFrequency = 1000 / 15;

    private timeSinceMediaConnectionUpdate: number = 0;
    private mediaConnectionUpdateInterval: number = 1000 / 1;

    private webcamDistanceQualityConfig: Array<[number, number]> = new Array(
        [
            SHOWBOAT.RemotePlayersZoneConfig.Distance_Visible,
            SHOWBOAT.RemotePlayersZoneConfig.Count_Visible,
        ],
        [
            SHOWBOAT.RemotePlayersZoneConfig.Distance_Hidden,
            SHOWBOAT.RemotePlayersZoneConfig.Count_Hidden,
        ],
        [
            SHOWBOAT.RemotePlayersZoneConfig.Distance_Mute,
            SHOWBOAT.RemotePlayersZoneConfig.Count_Mute,
        ],
        [
            SHOWBOAT.RemotePlayersZoneConfig.Distance_Off,
            SHOWBOAT.RemotePlayersZoneConfig.Count_Off,
        ]
    );

    private isInitializingPlayers: boolean = true; //Starts as true so anyone who asks knows the avatars aren't ready yet

    public get IsInitializingPlayers() {
        return this.isInitializingPlayers;
    }

    private isAwaitingInitialPlayerPositions: boolean = false;

    private isAwaitingPlayerPositionDataRequest: boolean = false; //Helps prevent multiple calls to the server
    private timeSinceLastSuccessfulUpdate: number = 0;
    private maximumSecondsToWaitForPosUpdate: number = 3;

    public get IsAwaitingInitialPlayerPositions() {
        return this.isAwaitingInitialPlayerPositions;
    }

    private eventMute: boolean = false;
    private silenceAudience: boolean;
    private spatialAudio: boolean;

    private mediaConnectedList: RemotePlayer[] = new Array(
        SHOWBOAT.RemotePlayersZoneConfig.Count_Visible
    );
    private mediaDisconnectedList: RemotePlayer[] = new Array();

    private runLoopInterval;

    constructor() {
        this.SetupAsync();
    }

    public async SetupAsync() {
        //Create asset container for avatar references
        //AvatarController.highLightLayer = null;

        SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerConnected.Add(
            this.OnRemotePlayerConnected.bind(this)
        );
        SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDisconnected.Add(
            this.OnRemotePlayerDisconnected.bind(this)
        );
        /*  SHOWBOAT.RemoteAvatarDataManager.OnPartitionChange.Add(this.OnRemotePlayerPartitionUpdated.bind(this)); */
        SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerRoomChange.Add(
            this.OnRemotePlayerRoomChange.bind(this)
        );

        SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Add(
            SHOWBOAT.UpdateType.indexOf(
                StringVariableHelper.ChangeEventNames.Camera
            ),
            this.OnRemoteCameraUpdate.bind(this)
        );
        SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Add(
            SHOWBOAT.UpdateType.indexOf(
                StringVariableHelper.ChangeEventNames.Mic
            ),
            this.OnRemoteMicUpdate.bind(this)
        );
        SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Add(
            SHOWBOAT.UpdateType.indexOf(
                StringVariableHelper.ChangeEventNames.LaserEnabled
            ),
            this.OnRemoteLaserUpdate.bind(this)
        );

        SHOWBOAT.ServerVariableManager.OnBookingVariableUpdate.Add(
            StringVariableHelper.SilenceAudienceEventName,
            this.OnPresenterSilenceAudienceChange.bind(this)
        );

        SHOWBOAT.ServerVariableManager.OnBookingVariableUpdate.Add(
            StringVariableHelper.SpatialAudioEventName,
            this.OnSpatialAudioChange.bind(this)
        );

        SHOWBOAT.WebSocketController.OnGroupMessage.Add(
            "GROUNDEDCHANGED",
            this.OnRemotePlayerGroundedChange.bind(this)
        );

        SHOWBOAT.UIEventManager.OnRoomChange.Add(
            this.OnLocalRoomChange.bind(this)
        );
        //SHOWBOAT.UIEventManager.OnEventMuteToggle.Add(this.OnEventMuteToggle.bind(this));
        SHOWBOAT.UIEventManager.OnPartionChange.Add(
            this.OnLocalPlayerPartitionUpdated.bind(this)
        );

        try {
            let silenceAudienceVariable =
                await SHOWBOAT.ServerVariableManager.getBookingVariable(
                    StringVariableHelper.SilenceAudienceEventName,
                    { muted: false }
                );

            if (silenceAudienceVariable) {
                this.silenceAudience =
                    silenceAudienceVariable.data.muted === true ||
                    silenceAudienceVariable.data.muted == "true"
                        ? true
                        : false;
            } else {
                throw new Error();
            }
                
        } catch (error) {
            SHOWBOAT.Logger.Error(
                "Getting initial silence audience variable failed"
            );
            SHOWBOAT.Logger.Error(error);
        }

        try {
            let spatialAudioVariable =
                await SHOWBOAT.ServerVariableManager.getBookingVariable(
                    StringVariableHelper.SpatialAudioEventName,
                    { value: true }
                );
            if (spatialAudioVariable) {
                this.spatialAudio =
                    spatialAudioVariable.data.spatialAudio === true ||
                    spatialAudioVariable.data.spatialAudio == "true"
                        ? true
                        : false;
            } else {
                throw new Error();
            }
                
        } catch (error) {
            SHOWBOAT.Logger.Error(
                "Getting initial spatial audio variable failed"
            );
            SHOWBOAT.Logger.Error(error);
        }

        this.roomID = SHOWBOAT.LocalAvatarDataManager.roomID;

        this.InitializeRemotePlayers();

        this.runLoopInterval = setInterval(this.RunPlayerUpdates, 100);
    }

    private RunPlayerUpdates = () => {
        this.GetPositionUpdatesFromServer();
        this.UpdateMediaConnections();
    };

    private InitializeRemotePlayers() {
        this.isInitializingPlayers = true;
        this.isAwaitingInitialPlayerPositions = true;

        let allPlayerData =
            SHOWBOAT.RemoteAvatarDataManager.getAllAvatarsInEvent();

        if (allPlayerData) {
            for (let i = 0; i < allPlayerData.length; i++) {
                this.OnRemotePlayerConnected(allPlayerData[i]);
            }
        }

        this.isInitializingPlayers = false;
        this.OnPlayersInitialized.Raise();
    }

    private ClearRemotePlayers() {
        let remotePlayerUserIDs: string[] = Array.from(
            this.remotePlayerMap.keys()
        );

        for (let i = 0; i < remotePlayerUserIDs.length; i++) {
            this.DestroyRemotePlayer(remotePlayerUserIDs[i]);
        }

        this.remotePlayerMap.clear();
        this.elapsedTimeSinceLastTransformUpdateRequest = 0;
    }

    private DestroyRemotePlayer(userID: string) {
        let remotePlayer: RemotePlayer = this.remotePlayerMap.get(userID);

        if (remotePlayer) {
            this.RemoveFromMediaTracking(remotePlayer);
            //remotePlayer.AvatarController.resetAppearance();
            //this.avatarPrefabPool.ReturnInstance(remotePlayer.AvatarController.transform);
            this.remotePlayerMap.delete(userID);
            remotePlayer.dispose();
            //BABYLON.SceneManager.DestroyScriptComponent(remotePlayer);
            remotePlayer = null;
        }
    }

    private async GetPositionUpdatesFromServer() {
        /*
        this.elapsedTimeSinceLastTransformUpdateRequest += this.getDeltaSeconds() * 1000;
        this.timeSinceLastSuccessfulUpdate += this.getDeltaSeconds() * 1000;

        if(this.elapsedTimeSinceLastTransformUpdateRequest >= this.transformUpdateFrequency) {              
            this.elapsedTimeSinceLastTransformUpdateRequest = 0;

            if(this.isAwaitingPlayerPositionDataRequest){
                if(this.timeSinceLastSuccessfulUpdate > 1000 * this.maximumSecondsToWaitForPosUpdate){
                    this.timeSinceLastSuccessfulUpdate = 0;
                }
                else return;
            }

            try {
                this.isAwaitingPlayerPositionDataRequest = true;
                let allPlayerPositionData = await SHOWBOAT.SocketIOController.GetAllPlayerPositionData(false);
                this.isAwaitingPlayerPositionDataRequest = false;
                this.timeSinceLastSuccessfulUpdate = 0;

                if(allPlayerPositionData) {
                    for(let i = 0; i < allPlayerPositionData.count; i++) {
                        this.OnRemotePlayerTransformUpdate(allPlayerPositionData.data[i]);  
                    }

                    if(this.isAwaitingInitialPlayerPositions){
                        this.isAwaitingInitialPlayerPositions = false;

                        this.OnInitialAvatarPositionsUpdated.Raise();
                    }
                }
            } catch(error) {
                SHOWBOAT.Logger.Error("Getting all player position data failed for this attempt.");
                SHOWBOAT.Logger.Error(error);
                this.isAwaitingPlayerPositionDataRequest = false;
                this.timeSinceLastSuccessfulUpdate = 0;
            };
        } 
        */
    }

    private UpdateMediaConnections() {
        this.timeSinceMediaConnectionUpdate += this.getDeltaSeconds() * 1000;

        if (
            this.timeSinceMediaConnectionUpdate >=
            this.mediaConnectionUpdateInterval
        ) {
            this.timeSinceMediaConnectionUpdate = 0;

            this.mediaConnectedList = this.mediaConnectedList.sort(
                this.SortRemotePlayersByDistance
            );
            this.mediaDisconnectedList = this.mediaDisconnectedList.sort(
                this.SortRemotePlayersByDistance
            );

            let connectedDistanceCutoff = Math.max(
                SHOWBOAT.RemotePlayersZoneConfig.Distance_Visible,
                SHOWBOAT.RemotePlayersZoneConfig.Distance_Nearby
            );

            //Removes anyone who is disqualified based on distance alone since the last check
            for (let i = 0; i < this.mediaConnectedList.length; i++) {
                if (this.mediaConnectedList[i] == null) break;
                else if (
                    this.mediaConnectedList[i].LatestDistanceCalculation >
                    connectedDistanceCutoff
                ) {
                    this.mediaDisconnectedList.push(this.mediaConnectedList[i]);
                    this.mediaConnectedList[i] = null;
                }
            }

            //Add anybody who qualifies from the off list to any empty spaces left in the on list
            if (
                this.mediaDisconnectedList.length > 0 &&
                this.mediaConnectedList[this.mediaConnectedList.length - 1] ==
                    null
            ) {
                let mediaConnectedListNeedsNewSort: boolean = false;

                for (let i = this.mediaConnectedList.length - 1; i >= 0; i--) {
                    if (
                        this.mediaConnectedList[i] != null ||
                        this.mediaDisconnectedList.length <= 0 ||
                        this.mediaDisconnectedList[0] == null
                    )
                        break;

                    if (
                        this.mediaDisconnectedList[0]
                            .LatestDistanceCalculation <=
                        connectedDistanceCutoff
                    ) {
                        this.mediaConnectedList[i] =
                            this.mediaDisconnectedList.shift();
                        mediaConnectedListNeedsNewSort = true;
                    }
                }

                if (mediaConnectedListNeedsNewSort)
                    this.mediaConnectedList = this.mediaConnectedList.sort(
                        this.SortRemotePlayersByDistance
                    );
            }

            //If there are still open spots at the bottom of the on list, we do not need to do this check.
            //Those slots would have already been filled above with qualifying players
            if (
                this.mediaConnectedList[this.mediaConnectedList.length - 1] !=
                null
            ) {
                let deadbandThreshold =
                    SHOWBOAT.RemotePlayersZoneConfig.WebcamDeadbandThreshold;

                //Compare top players of off list to bottom players of on list and adjust appropriately if they surpass the threshold for overtaking
                while (
                    this.mediaDisconnectedList.length > 0 &&
                    this.mediaDisconnectedList[0].LatestDistanceCalculation +
                        deadbandThreshold <
                        this.mediaConnectedList[
                            this.mediaConnectedList.length - 1
                        ].LatestDistanceCalculation
                ) {
                    this.mediaConnectedList.unshift(
                        this.mediaDisconnectedList.shift()
                    );
                    this.mediaDisconnectedList.push(
                        this.mediaConnectedList.pop()
                    );
                }
            }

            //Set final webcam visibilities
            for (let i = 0; i < this.mediaConnectedList.length; i++) {
                let remotePlayer = this.mediaConnectedList[i];
                if (remotePlayer != null) {
                    remotePlayer.SetMediaConnected(true);
                }
            }

            for (let i = 0; i < this.mediaDisconnectedList.length; i++) {
                let remotePlayer = this.mediaDisconnectedList[i];
                if (remotePlayer != null) {
                    remotePlayer.SetMediaConnected(false);
                }
            }
        }
    }

    private SortRemotePlayersByDistance(a: RemotePlayer, b: RemotePlayer) {
        if (a == null) return 1;
        if (b == null) return -1;
        if (
            a.LatestMediaAdjustedDistanceCalculation <
            b.LatestMediaAdjustedDistanceCalculation
        )
            return -1;
        if (
            a.LatestMediaAdjustedDistanceCalculation >
            b.LatestMediaAdjustedDistanceCalculation
        )
            return 1;
        return 0;
    }

    private OnRemotePlayerConnected(
        remotePlayerData: SHOWBOAT.AvatarData
    ): RemotePlayer {
        if (
            remotePlayerData.userID ==
            SHOWBOAT.LocalAvatarDataManager.avatarData.userID
        )
            return;
        if (
            !remotePlayerData.isPresenting &&
            remotePlayerData.roomID !== "backstage" &&
            remotePlayerData.roomID != SHOWBOAT.LocalAvatarDataManager.roomID
        )
            return;

        let existingRemotePlayer = this.remotePlayerMap.get(
            remotePlayerData.userID
        );

        if (!existingRemotePlayer) {
            //TODO delete this if we are happy with the avatarPrefabPool
            /*
            let newAvatarInstance = BABYLON.SceneManager.InstantiatePrefab(
                this.avatarAssetContainer, 
                this.avatarPrefabName,
                remotePlayerData.firstName + " " + remotePlayerData.lastName);
                */

            let newRemotePlayer = new RemotePlayer({
                remotePlayerData: remotePlayerData,
                eventMute: this.eventMute,
                silenceAudience: this.silenceAudience,
                spatialAudio: this.spatialAudio,
            });

            this.remotePlayerMap.set(remotePlayerData.userID, newRemotePlayer);

            this.AddToMediaTracking(newRemotePlayer);

            return newRemotePlayer;
        } else {
            SHOWBOAT.Logger.Error(
                "Already have userID: " + remotePlayerData.userID
            );
            //TODO Update remoteAvatar with new data if needed
            //existingRemotePlayer.blah(remotePlayerData);
        }
    }

    private OnRemotePlayerDisconnected = (
        remotePlayerData: SHOWBOAT.AvatarData
    ) => {
        this.DestroyRemotePlayer(remotePlayerData.userID);
    };

    private OnRemotePlayerTransformUpdate = (
        remotePlayerTransformData: SHOWBOAT.PositionData
    ) => {
        if (
            !remotePlayerTransformData ||
            remotePlayerTransformData.userID ==
                SHOWBOAT.LocalAvatarDataManager.userID
        )
            return;

        if (
            remotePlayerTransformData.userID !=
            SHOWBOAT.LocalAvatarDataManager.userID
        ) {
            let remotePlayer = this.remotePlayerMap.get(
                remotePlayerTransformData.userID
            );

            if (remotePlayer) {
                let positionArray = remotePlayerTransformData.position;
                let rotationArray = remotePlayerTransformData.rotation;
                remotePlayer.setPosition(
                    positionArray[0],
                    positionArray[1],
                    positionArray[2]
                );
                remotePlayer.setRotation(
                    rotationArray[0],
                    rotationArray[1],
                    rotationArray[2]
                );
                remotePlayer.recalculateLaser();
            }
        }
    };

    private OnRemoteCameraUpdate(remotePlayerData: SHOWBOAT.AvatarData) {
        let remotePlayer = this.remotePlayerMap.get(remotePlayerData.userID);
        if (remotePlayer) {
            remotePlayer.updateCameraUsage(remotePlayerData.cameraEnabled);
        }
    }

    private OnRemoteMicUpdate(remotePlayerData: SHOWBOAT.AvatarData) {
        let remotePlayer = this.remotePlayerMap.get(remotePlayerData.userID);
        if (remotePlayer) {
            remotePlayer.updateMicUsage(remotePlayerData.micEnabled);
        }
    }

    private OnRemoteCamAndMicUpdate(remotePlayerData: SHOWBOAT.AvatarData) {
        let remotePlayer = this.remotePlayerMap.get(remotePlayerData.userID);
        if (remotePlayer) {
            remotePlayer.updateCameraUsage(remotePlayerData.cameraEnabled);
            remotePlayer.updateMicUsage(remotePlayerData.micEnabled);
        }
    }

    private OnRemoteLaserUpdate(remotePlayerData: SHOWBOAT.AvatarData) {
        let remotePlayer = this.remotePlayerMap.get(remotePlayerData.userID);
        if (remotePlayer)
            remotePlayer.updateLaserUsage(remotePlayerData.laserEnabled);
    }

    /*
    public GetRemotePlayerPosition(targetPlayerID : string) : BABYLON.Vector3 | null {
        let player = this.remotePlayerMap.get(targetPlayerID);
        if(player) return player.getPosition().clone();
        else return null;
    }

    public GetRemotePlayerLookDirection(targetPlayerID : string) : BABYLON.Vector3 | null {
        let player = this.remotePlayerMap.get(targetPlayerID);
        if(player) return player.getLookDirection().clone();
        else return null;
    }
    */

    private async OnLocalRoomChange(newRoomID: string) {
        //this.scene.unregisterBeforeRender(this.RunPlayerUpdates);
        clearInterval(this.runLoopInterval);

        this.ClearRemotePlayers();
        this.InitializeRemotePlayers();

        this.roomID = newRoomID;

        let data = null;

        while (data == null) {
            //data = await SHOWBOAT.SocketIOController.GetAllPlayerPositionData();
            data = await SHOWBOAT.SocketIOController.GetAllPlayerPositionData(
                true
            );
            //data = await SHOWBOAT.SocketIOController.GetAllPlayerPositionData(true, this.roomID);
        }

        //this.scene.registerBeforeRender(this.RunPlayerUpdates);
        clearInterval(this.runLoopInterval);
    }

    private OnRemotePlayerRoomChange(
        oldRoom: string,
        avatarData: SHOWBOAT.AvatarData
    ) {
        let remotePlayer = this.remotePlayerMap.get(avatarData.userID);

        if (remotePlayer != null) {
            if (
                !avatarData.isPresenting &&
                avatarData.roomID !== "backstage" &&
                avatarData.roomID != SHOWBOAT.LocalAvatarDataManager.roomID
            ) {
                this.OnRemotePlayerDisconnected(avatarData);
            }
        } else {
            if (
                avatarData.isPresenting ||
                avatarData.roomID === "backstage" ||
                avatarData.roomID == SHOWBOAT.LocalAvatarDataManager.roomID
            ) {
                this.OnRemotePlayerConnected(avatarData);
            }
        }
    }

    private OnEventMuteToggle(eventMute: boolean) {
        this.eventMute = eventMute;

        let remotePlayerMapValues: RemotePlayer[] = Array.from(
            this.remotePlayerMap.values()
        );

        for (let i = 0; i < remotePlayerMapValues.length; i++) {
            remotePlayerMapValues[i].SetEventMute(this.eventMute);
        }
    }

    private OnPresenterSilenceAudienceChange(updateObj: SHOWBOAT.VariableData) {
        const data = updateObj.data;
        
        if (typeof data.muted === "string")
            data.muted =
                data.muted === "true" ? true : false;

        this.silenceAudience = data.muted;

        let remotePlayerMapValues: RemotePlayer[] = Array.from(
            this.remotePlayerMap.values()
        );

        for (let i = 0; i < remotePlayerMapValues.length; i++) {
            remotePlayerMapValues[i].SetSilenceAudience(this.silenceAudience);
        }
    }

    private OnSpatialAudioChange(updateObj: SHOWBOAT.VariableData) {
        const data = updateObj.data;
        
        if (typeof data.spatialAudio === "string")
            data.spatialAudio = data.spatialAudio === "true" ? true : false;

        this.spatialAudio = data.spatialAudio

        let remotePlayerMapValues: RemotePlayer[] = Array.from(
            this.remotePlayerMap.values()
        );

        for (let i = 0; i < remotePlayerMapValues.length; i++) {
            remotePlayerMapValues[i].SetSpatialAudio(this.spatialAudio);
        }
    }

    private OnLocalPlayerPartitionUpdated(partition: string) {
        let remotePlayerMapValues: RemotePlayer[] = Array.from(
            this.remotePlayerMap.values()
        );

        for (let i = 0; i < remotePlayerMapValues.length; i++) {
            remotePlayerMapValues[i].UpdateLocalPlayerChannel(partition);
        }
    }

    private OnRemotePlayerPartitionUpdated(avatarData: SHOWBOAT.AvatarData) {
        if (avatarData.userID == SHOWBOAT.LocalAvatarDataManager.userID) return;

        let remotePlayer: RemotePlayer = this.remotePlayerMap.get(
            avatarData.userID
        );

        if (remotePlayer != null) {
            if (
                !avatarData.isPresenting &&
                avatarData.roomID !== "backstage" &&
                avatarData.roomID != SHOWBOAT.LocalAvatarDataManager.roomID
            ) {
                this.OnRemotePlayerDisconnected(avatarData);
            } else {
                let partitionString = "attendees";

                if (avatarData.isPresenting) {
                    partitionString = "presenter";
                }

                if (avatarData.roomID === "backstage") {
                    partitionString = "backstage";
                }
                remotePlayer.UpdateRemotePlayerChannel(partitionString);
            }
        } else {
            if (avatarData.isPresenting || avatarData.roomID === "backstage") {
                this.OnRemotePlayerConnected(avatarData);
            } else {
                return;
            }
        }
    }

    private OnRemotePlayerGroundedChange(groundedChanged: SHOWBOAT.MessageReportData) {
        let userID = groundedChanged.sendingUserID;

        let remotePlayer = this.remotePlayerMap.get(userID);

        if (remotePlayer != null) {
            let grounded: boolean = groundedChanged.payload.grounded;

            if (typeof grounded === "string")
                grounded = grounded === "true" ? true : false;

            remotePlayer.updateGrounded(grounded);
        }
    }

    private AddToMediaTracking(remotePlayer: RemotePlayer) {
        if (
            this.mediaDisconnectedList.find(
                (element) => element == remotePlayer
            ) == undefined &&
            this.mediaConnectedList.find(
                (element) => element == remotePlayer
            ) == undefined
        ) {
            this.mediaDisconnectedList.push(remotePlayer);
        }
    }

    private RemoveFromMediaTracking(remotePlayer: RemotePlayer) {
        for (let i = 0; i < this.mediaDisconnectedList.length; i++) {
            if (this.mediaDisconnectedList[i] == remotePlayer) {
                this.mediaDisconnectedList.splice(i, 1);
                return;
            }
        }

        for (let i = 0; i < this.mediaConnectedList.length; i++) {
            if (this.mediaConnectedList[i] == remotePlayer) {
                this.mediaConnectedList[i] = null;
                return;
            }
        }
    }

    public getDownStreamControllerByID(
        userID: string
    ): SHOWBOAT.LiveswitchDownstreamController {
        let remotePlayer = this.remotePlayerMap.get(userID);

        if (remotePlayer) return remotePlayer.DownStreamController;
        else return null;
    }

    private getDeltaSeconds(): number {
        return 0.1;
    }
}
