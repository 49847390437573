import { RemotePlayerHandler } from "./RemotePlayerHandler";
import axios from "axios";

export enum ReportingType{
    Error,
    Success,
    Msg
}

export class Testing{

    private static appAPIUrl = `https://f96l7jj519.execute-api.us-east-1.amazonaws.com/dev`;

    private static loginCode : string;
    private static doStream : boolean = true;
    private static userID : string;
    private static eventID : string;
    private static roomID = "";

    private static firstNames =  ["Adam", "Alex", "Aaron", "Ben", "Carl", "Dan", "David", "Edward", "Fred", "Frank", "George", "Hal", "Hank", "Ike", "John", "Jack", "Joe", "Larry", "Monte", "Matthew", "Mark", "Nathan", "Otto", "Paul", "Peter", "Roger", "Roger", "Steve", "Thomas", "Tim", "Ty", "Victor", "Walter"];   
    private static lastNames = ["Anderson", "Ashwoon", "Aikin", "Bateman", "Bongard", "Bowers", "Boyd", "Cannon", "Cast", "Deitz", "Dewalt", "Ebner", "Frick", "Hancock", "Haworth", "Hesch", "Hoffman", "Kassing", "Knutson", "Lawless", "Lawicki", "Mccord", "McCormack", "Miller", "Myers", "Nugent", "Ortiz", "Orwig", "Ory", "Paiser", "Pak", "Pettigrew", "Quinn", "Quizoz", "Ramachandran", "Resnick", "Sagar", "Schickowski", "Schiebel", "Sellon", "Severson", "Shaffer", "Solberg", "Soloman", "Sonderling", "Soukup", "Soulis", "Stahl", "Sweeney", "Tandy", "Trebil", "Trusela", "Trussel", "Turco", "Uddin", "Uflan", "Ulrich", "Upson", "Vader", "Vail", "Valente", "Van Zandt", "Vanderpoel", "Ventotla", "Vogal", "Wagle", "Wagner", "Wakefield", "Weinstein", "Weiss", "Woo", "Yang", "Yates", "Yocum", "Zeaser", "Zeller", "Ziegler", "Bauer", "Baxster", "Casal", "Cataldi", "Caswell", "Celedon", "Chambers", "Chapman", "Christensen", "Darnell", "Davidson", "Davis", "DeLorenzo", "Dinkins", "Doran", "Dugelman", "Dugan", "Duffman", "Eastman", "Ferro", "Ferry", "Fletcher", "Fietzer", "Hylan", "Hydinger", "Illingsworth", "Ingram", "Irwin", "Jagtap", "Jenson", "Johnson", "Johnsen", "Jones", "Jurgenson", "Kalleg", "Kaskel", "Keller", "Leisinger", "LePage", "Lewis", "Linde", "Lulloff", "Maki", "Martin", "McGinnis", "Mills", "Moody", "Moore", "Napier", "Nelson", "Norquist", "Nuttle", "Olson", "Ostrander", "Reamer", "Reardon", "Reyes", "Rice", "Ripka", "Roberts", "Rogers", "Root", "Sandstrom", "Sawyer", "Schlicht", "Schmitt", "Schwager", "Schutz", "Schuster", "Tapia", "Thompson", "Tiernan", "Tisler" ];
    private static companySuffix = ["LLC", "Inc.", "Corp."];

    private static botID : string = "unknown";
    private static testInstanceID : string = "unknown";


    private static channelClaims : Map<SHOWBOAT.ShowboatChannelType, fm.liveswitch.Channel> = new Map<SHOWBOAT.ShowboatChannelType, fm.liveswitch.Channel>();
    private static localMedia : fm.liveswitch.LocalMedia;
    private static htmlVideoElement : HTMLVideoElement;
    private static channel : fm.liveswitch.Channel;
    private static connection : fm.liveswitch.SfuUpstreamConnection;
    private static videoStream : fm.liveswitch.VideoStream;
    private static audioStream : fm.liveswitch.AudioStream;
    private static stream : MediaStream;

    private static position_x : number;
    private static position_y : number;
    private static position_z : number;

    private static rotation_x : number;
    private static rotation_y : number;
    private static rotation_z : number;

    private static avatarData : SHOWBOAT.AvatarData;

    private static buttonElement;

    private static angle : number = 0;
    private static radius : number = 2;
    private static speed : number = 0;

    private static offset_x : number = 0;
    private static offset_z : number = 0;

    private static useMediaID = false;
    private static videoServerAppID : string;
    private static videoServer : string;

    private static streamingAvatarCanvas : HTMLCanvasElement;
    private static streamingAvatarCanvasContext : CanvasRenderingContext2D;
    private static avatarVideoStream : MediaStream;

    private static remotePlayerHandler : RemotePlayerHandler;

    private static randomPositionRectangles = [];

    private static moveAfter : number = 0;
    private static moveUntil : number = 0;

    private static attendeesIDToken : string = "";
    private static attendeesID : string = "";
    private static serverToken : string = "";
    
    private static getRandomFirst() {
        let index = Math.floor(Math.random() * Math.floor(Testing.firstNames.length - 1));
        return Testing.firstNames[index];
    }

    private static getRandomLast() {
        let index = Math.floor(Math.random() * Math.floor(Testing.lastNames.length - 1));
        return Testing.lastNames[index];
    }

    private static getRandomCompanySuffix() {
        let index = Math.floor(Math.random() * Math.floor(Testing.companySuffix.length - 1));
        return Testing.companySuffix[index];
    }

    public static Init(){

        (window as any).Testing = Testing;

        //Listen for a click to get us going
        Testing.buttonElement = document.getElementById("startButton");
        if(Testing.buttonElement){
            Testing.buttonElement.addEventListener("click", Testing.onButtonClick);
        }

    }

    private static onButtonClick(){

        //Remove button listener
        Testing.buttonElement.removeEventListener("click", Testing.onButtonClick);

        Testing.buttonElement.disabled = true;

        setTimeout(Testing.Load, Math.random() * (window as any).config.randomDelay * 1000);
    }

    private static Load(){

        //Assign a random position to this avatar
        Testing.radius = Math.random()*2  + 1;
        Testing.createSpawnAreas();
        Testing.getRandomPosition();
        let direction = Math.random() > 0.5 ? 1 : -1;

        Testing.botID = Testing.GetQueryParam("botID");
        if(!Testing.botID){
            Testing.botID = "Unknown";
        }

        Testing.testInstanceID = Testing.GetQueryParam("testInstanceID");
        if(!Testing.testInstanceID){
            Testing.testInstanceID = "Unknown";
        }
        

        //Init our local data handler
        SHOWBOAT.LocalAvatarDataManager.Init();
        //SHOWBOAT.RemoteAvatarDataManager.Init();

        //Get params

        //Login Code
        Testing.loginCode = Testing.GetQueryParam("login");
        //Ensure login code
        if(!Testing.loginCode){
            Testing.loginCode = (window as any).config.loginCode;
        }
    
        //Enforce an attendees code on testing
        if(Testing.loginCode.startsWith("dev") && !Testing.loginCode.startsWith("devA")){
            Testing.loginCode = Testing.loginCode.replace("dev","devA");
        }

        //Should we stream video
        let noStream = Testing.GetQueryParam("nostream");        
        if(noStream != null && noStream.length > 0){
            Testing.doStream = false;
        } else {
            Testing.doStream = (window as any).config.doStream;
        }
        

        Testing.speed = 0.2 * (2 * Math.PI * direction) * ((window as any).config.sendTransformFrequency / 1000);
        

        Testing.streamingAvatarCanvas = document.createElement("canvas") as HTMLCanvasElement;
        Testing.streamingAvatarCanvasContext = Testing.streamingAvatarCanvas.getContext("2d");
        Testing.streamingAvatarCanvas.width = 240;
        Testing.streamingAvatarCanvas.height =  240;
        Testing.avatarVideoStream = (Testing.streamingAvatarCanvas as any).captureStream(15);

      
        Testing.Connect();
    }   

    private static backoffSeconds : number = 500;

    private static async ReportResults(loginCode:string, type:ReportingType, msg:string, botName, botID : string, testInstanceID : string){

        let typeText = "";
        switch(type){
            case ReportingType.Error:
                typeText = "Error";
                break;
            case ReportingType.Success:
                typeText = "Success";
                break;
            case ReportingType.Msg:
                typeText = "Msg";
                break;
        }

        let payload = {
            botName : botName,
            type : typeText,
            msg : msg,
            loginCode : loginCode,
            botID : botID,
            testInstanceID : testInstanceID
        }

        try {
            console.log("Reporting results to server: " + JSON.stringify(payload));
            const response = await axios.post('https://7wwc3aw6l7.execute-api.us-east-1.amazonaws.com/dev/log', JSON.stringify(payload));
            console.log("Successfully reported results: ", response);
        } catch(e) {
            console.error(e);
            Testing.backoffSeconds *= 2;
            setTimeout(()=>{
                Testing.ReportResults(loginCode, type, msg, botName, botID, testInstanceID);
            },Testing.backoffSeconds)
        }

    }

    private static async Connect(){

        const params = {
            loginCode: Testing.loginCode,
            timestamp: new Date().getTime()
        };

        axios.defaults.baseURL = Testing.appAPIUrl;

        try{
            const loginApiResponse = await axios.get('login-v2', { params });

            console.log("params: ", params);

            console.log("loginApiResponse: ", loginApiResponse);
            
            if (loginApiResponse && loginApiResponse.data) {

                if (loginApiResponse.data.success) {

                    let loginResult = loginApiResponse.data as SHOWBOAT.LoginResult;

                    if (loginResult.token) {
                        Testing.attendeesIDToken = loginResult.token;
                    }

                    if (loginResult.attendeesID) {
                        Testing.attendeesID = loginResult.attendeesID;
                    }

                    if (loginResult.eventID) {
                        Testing.eventID = loginResult.eventID;
                    }

                    if (loginResult.userID) {
                        SHOWBOAT.LocalAvatarDataManager.userID = loginResult.userID;
                    }

                    Testing.attendeesIDToken = loginResult.token;

                    console.log("First token: ", Testing.attendeesIDToken);

                    Testing.videoServer = loginResult.videoServer;
                    Testing.videoServerAppID = loginResult.videoServerAppID;

                    console.log("Calling to connect:", loginResult.gameServer, loginResult.gamePort, false, SHOWBOAT.LocalAvatarDataManager.userID);
                    
                    SHOWBOAT.WebSocketController.OnConnectionTimeout.Add(Testing.handleGameServerFail);

                    Testing.connectionTimeoutID = setTimeout(function () {
                        Testing.handleGameServerFail();
                    }, 20000);

                    const connectResponse = await SHOWBOAT.WebSocketController.Connect(loginResult.gameServer, loginResult.gamePort, false, {
                        userID: SHOWBOAT.LocalAvatarDataManager.userID
                    });

                    if (connectResponse) {

                        SHOWBOAT.Logger.Log("OnLocalPlayerConnected");

                        if (Testing.connectionTimeoutID) {
                            clearTimeout(Testing.connectionTimeoutID);
                        }

                        Testing.LoginToWebsocket();

                    } else {
                        Testing.handleGameServerFail();
                    }
                } else {
                    console.error("Login Failed");
                    let msg = "Login Failed " + JSON.stringify(loginApiResponse);
                    Testing.ReportResults(Testing.loginCode, ReportingType.Error, msg, "No Name", Testing.botID, Testing.testInstanceID);
                }
            } else {
                console.error("Bad Login Response");
                let msg = "Bad Login Response " + JSON.stringify(loginApiResponse);
                Testing.ReportResults(Testing.loginCode, ReportingType.Error, msg, "No Name", Testing.botID, Testing.testInstanceID);
            }
        } catch(error){
            let msg = "Error contacting AWS login endpoint " + Testing.appAPIUrl + "/login. " + JSON.stringify(error);
            Testing.ReportResults(Testing.loginCode, ReportingType.Error, msg,  "No Name", Testing.botID, Testing.testInstanceID);
        }
                
    }

    private static connectionTimeoutID : NodeJS.Timeout;

    private static handleGameServerFail(){
        
        clearTimeout(Testing.connectionTimeoutID);

        let msg = "Timeout connecting to Socket";
        
        Testing.ReportResults(Testing.loginCode, ReportingType.Error, msg, "No Name", Testing.botID, Testing.testInstanceID);

        //Retry
        Testing.Connect();
    }

    private static async LoginToWebsocket() {
        //Create mock avatar data
        let avatarData = new SHOWBOAT.AvatarData();
        avatarData.firstName = Testing.getRandomFirst();
        //avatarData.firstName = Testing.namePrefix + "_" + Testing.getRandomFirst();
        avatarData.lastName = Testing.getRandomLast();
        avatarData.company = (Math.random() < 0.7 ? Testing.getRandomLast() : Testing.getRandomLast() + " & " + Testing.getRandomLast()) + " " + Testing.getRandomCompanySuffix()
        avatarData.face = Math.floor(Math.random() * 6);  
        avatarData.color = Math.floor(Math.random() * 6);
        avatarData.cameraEnabled = Testing.doStream;
        avatarData.micEnabled = Testing.doStream;

        Testing.avatarData = avatarData;

        if(!Testing.doStream){
            avatarData.firstName = "__BOT" + avatarData.firstName;
        }
        
    
        try{
            //Get Login token
            const dataGetToken = await axios.post(
                "token-create-v2",
                JSON.stringify({
                    attendeesIDToken: Testing.attendeesIDToken,
                    roomName: "",
                    roomType: "",
                })
            );

            console.log("Data get token response:", dataGetToken);

            Testing.serverToken = dataGetToken.data;

            console.log("Second token: ", Testing.serverToken);

            console.log("Calling to login:", Testing.avatarData, Testing.serverToken)

            //Login to server
            await SHOWBOAT.WebSocketController.Login({
                loginUserData: Testing.avatarData,
                jwt: Testing.serverToken
            });

            //Client must now wait for OnLocalPlayerLoggedIn to raise to signify login flow is complete
            SHOWBOAT.WebSocketController.OnLocalPlayerLoggedIn.Add(
                Testing.handleWebsocketLoginSuccess
            );
        } catch(err) {
            console.log("Error logging in to server:", err);
        }
    }

    private static async handleWebsocketLoginSuccess(response: SHOWBOAT.LoginReturnData) {
        let msg : string;
            if(!response.succeeded){
                msg = "Login error on Showboat socket server, userID: " + response.userID;
                Testing.ReportResults(Testing.loginCode, ReportingType.Error, msg, SHOWBOAT.LocalAvatarDataManager.firstName + " " + SHOWBOAT.LocalAvatarDataManager.lastName, Testing.botID, Testing.testInstanceID);
                return;
            } else {
                msg = "Success: userID:" + response.userID;
                Testing.ReportResults(Testing.loginCode, ReportingType.Success, msg, SHOWBOAT.LocalAvatarDataManager.firstName + " " + SHOWBOAT.LocalAvatarDataManager.lastName, Testing.botID, Testing.testInstanceID);
            }

            clearTimeout(Testing.connectionTimeoutID);

           
            Testing.userID = response.userID;
            Testing.roomID = response.roomID;

            //Prep local avatar data
            SHOWBOAT.LocalAvatarDataManager.firstName = Testing.avatarData.firstName;
            SHOWBOAT.LocalAvatarDataManager.lastName = Testing.avatarData.lastName;
            SHOWBOAT.LocalAvatarDataManager.company = Testing.avatarData.company;
            SHOWBOAT.LocalAvatarDataManager.face = Testing.avatarData.face;
            SHOWBOAT.LocalAvatarDataManager.color = Testing.avatarData.color;
            SHOWBOAT.LocalAvatarDataManager.cameraEnabled = Testing.avatarData.cameraEnabled;
            SHOWBOAT.LocalAvatarDataManager.micEnabled = Testing.avatarData.micEnabled;            
            
            SHOWBOAT.LocalAvatarDataManager.userID = Testing.userID;
            SHOWBOAT.LocalAvatarDataManager.role = "attendees";
            SHOWBOAT.LocalAvatarDataManager.eventID = Testing.eventID;
            SHOWBOAT.LocalAvatarDataManager.roomID = response.roomID;

            
            //Get list of avatars warmed up to prevent errors in magic
            let getUserDataReturnData = await SHOWBOAT.RemoteAvatarDataManager.RequestAllPlayerData();  

            SHOWBOAT.Logger.Log("GetUserDataReturnData response:", getUserDataReturnData);
            
            if (!getUserDataReturnData) {
                msg = "Error retrieving user data";
                Testing.ReportResults(Testing.loginCode, ReportingType.Error, msg, SHOWBOAT.LocalAvatarDataManager.firstName + " " + SHOWBOAT.LocalAvatarDataManager.lastName, Testing.botID, Testing.testInstanceID);
                return;
            }

            console.log("User data retrieved:", getUserDataReturnData);            
            SHOWBOAT.RemoteAvatarDataManager.setInitialAvatarData(getUserDataReturnData as SHOWBOAT.AvatarData[]);

            //Simulate we are done loading
            SHOWBOAT.LocalAvatarDataManager.loadComplete = true;
            SHOWBOAT.WebSocketController.UpdatePlayerData({
                updateType: SHOWBOAT.UpdateType.indexOf("LoadComplete"),
                playerData: {},
            })
            

            //Set up an animation loop
            if((window as any).config.sendTransformFrequency > 0){

                Testing.moveAfter = Date.now();
                Testing.setMoveUntil();

                setInterval(Testing.sendPosition, (window as any).config.sendTransformFrequency);
            }

            //Setup faux load pulling position data on server
            //setInterval(Testing.getPosition, (window as any).config.pullRemoteTransformFrequency);
            setTimeout(Testing.getPosition, (window as any).config.pullRemoteTransformFrequency);
            

            //Send our video to Liveswitch
            if(Testing.doStream){


                let deviceID = SHOWBOAT.LiveSwitchClientController.generateUUID();
                let clientID = "ShowboatWebClient_" + (SHOWBOAT.LocalAvatarDataManager.eventID)

                SHOWBOAT.LiveSwitchClientController.createClient(Testing.videoServerAppID, SHOWBOAT.LocalAvatarDataManager.userID, deviceID, clientID, Testing.videoServer);

                //Register with liveswitch
                let registerResult = await SHOWBOAT.LiveSwitchClientController.register();
                
                if(registerResult){

                    //Start video stream
                    if(SHOWBOAT.LiveSwitchClientController.channels == null || SHOWBOAT.LiveSwitchClientController.channels.length == 0){
                        console.log("Error, missing channels");
                        return;
                    }

                    

                    await SHOWBOAT.LiveswitchMediaDownstreamController.Start(Testing.eventID);

                    document.body.appendChild( SHOWBOAT.LiveswitchMediaDownstreamController.getHTMLVideoElement());
                   
                    Testing.setChannelClaims();  

                    //Chose a random video for this avatar
                    let index = Math.floor(Math.random() * Math.floor(20)) + 1;
                    let src = `https://showboattesting.inputinput.com/assets/videos/FS${index}.mp4`;

                    Testing.htmlVideoElement = document.getElementById("sbvideo") as HTMLVideoElement;
                    Testing.htmlVideoElement.src = src;
                    Testing.htmlVideoElement.loop = true;
                    Testing.htmlVideoElement.crossOrigin = 'anonymous';     
                    Testing.htmlVideoElement.volume = 0.5;   
                    Testing.htmlVideoElement.muted = true;            
                    await Testing.htmlVideoElement.play();

                    Testing.htmlVideoElement.addEventListener('canplay', Testing.createStream);
                    if (Testing.htmlVideoElement.readyState >= 3) {                         
                        await Testing.createStream();
                    }
                }
            }

            let intakeData: Object = {
                firstName: Testing.avatarData.firstName,
                lastName: Testing.avatarData.lastName,
                company: Testing.avatarData.company,
                emailAddress: Testing.avatarData.firstName + "@email.com",
                phone: "1234567890",
                address: "123 Anywhere Lane",
            };

            
            Testing.postIntakeDataToAPI(intakeData);
    }

    private static intakePostBackoff = 1000;
    private static async postIntakeDataToAPI(intakeData : any){
        try {
            let response = await axios.post("https://p1j5nq983d.execute-api.us-east-1.amazonaws.com/dev/intake", {
                socketID: SHOWBOAT.LocalAvatarDataManager.userID,
                intakeData,
                loginCode: Testing.loginCode,
                attendeesIDArray: [],
            });
        } catch (e){
            Testing.intakePostBackoff = Testing.intakePostBackoff * 2; 
            setTimeout(() => {
                Testing.postIntakeDataToAPI(intakeData);
            }, Testing.intakePostBackoff);
        }
    }


    private static async createStream() : Promise<boolean>{

        Testing.htmlVideoElement.removeEventListener('canplay',Testing.createStream);

        setInterval(Testing.drawLoop, 100);

        if (Testing.stream) {
            return true;
        }

        let aVideo : any = Testing.htmlVideoElement;


        if (aVideo.captureStream) {
            try{
                Testing.stream = aVideo.captureStream();      
            } catch (err) {
                console.log(err);
            }          
        } else if (aVideo.mozCaptureStream) {
            Testing.stream = aVideo.mozCaptureStream();                
        } else {
            return false;
        }

        Testing.localMedia = new fm.liveswitch.LocalMedia(Testing.stream, Testing.avatarVideoStream);

        await Testing.localMedia.start();

        Testing.videoStream = new fm.liveswitch.VideoStream(this.localMedia);
        Testing.audioStream = new fm.liveswitch.AudioStream(this.localMedia);


        if(Testing.useMediaID){
            Testing.channel = Testing.channelClaims.get(SHOWBOAT.ShowboatChannelType.Attendees);
            let mediaID = Testing.getMediaID();
            //Testing.connection = Testing.channel.createSfuUpstreamConnection(Testing.audioStream, Testing.videoStream, "Attendees_" + Testing.userID); 
        } else {
            Testing.channel = await SHOWBOAT.LiveSwitchClientController.getChannel(SHOWBOAT.ShowboatChannelType.Attendees, Testing.eventID, Testing.roomID);
            Testing.connection = Testing.channel.createSfuUpstreamConnection(Testing.audioStream, Testing.videoStream); 
        }

        try {
            let startConnectionResult = await Testing.connection.open();
        } catch(e) {
            console.error(e);
        }

        await SHOWBOAT.LiveswitchDownstreamController.init(Testing.eventID, Testing.roomID, SHOWBOAT.LocalAvatarDataManager.avatarData);

        this.remotePlayerHandler = new RemotePlayerHandler();
        
        return true;
    }

    private static setMoveAfter(){
        let pauseDuration = (window as any).config.pauseMinSeconds + Math.random() * ((window as any).config.pauseMaxSeconds - (window as any).config.pauseMinSeconds);
        Testing.moveAfter = Date.now() + pauseDuration * 1000;
    }

    private static setMoveUntil(){
        let randomMoveDuration = (window as any).config.moveDurationMinSeconds + Math.random() * ((window as any).config.moveDurationMaxSeconds - (window as any).config.moveDurationMinSeconds);
        Testing.moveUntil = Testing.moveAfter + randomMoveDuration * 1000;
    }

    private static sendPosition(){

        let x : number = Testing.position_x;
        let y : number = Testing.position_y;
        let z : number = Testing.position_z;

        if((window as any).config.avatarsDoMove){

            let now = Date.now();

            //Check if we are still paused
            if(now < Testing.moveAfter) return;

            if(now > Testing.moveUntil){
                Testing.setMoveAfter();
                Testing.setMoveUntil();

                return;
            }

            Testing.angle += Testing.speed;
            Testing.offset_x = Math.cos(Testing.angle) * Testing.radius;
            Testing.offset_z = Math.sin(Testing.angle) * Testing.radius;

            if(Testing.angle > 6.5){
                Testing.angle -= (Math.PI * 2);
            } else if(Testing.angle < 0){
                Testing.angle += (Math.PI * 2);
            }

            x += Testing.offset_x;
            z += Testing.offset_z;

        }

        //Concatenate the arrays
        let positionArray = [x, y, z];
        let rotationArray = [Testing.rotation_x, Testing.rotation_y, Testing.rotation_z];
        
        let upData = { transformData: positionArray.concat(rotationArray)};

        console.log("Sending up data:", upData);

        SHOWBOAT.WebSocketController.UpdateTransform(upData)
    }

    private static async getPosition(){
        //Simulate load on server pulling down data
        //let positionData = SHOWBOAT.SocketIOController.GetAllPlayerPositionData(false, Testing.roomID);
        let positionData = await SHOWBOAT.WebSocketController.GetTransforms({
            lastTick: 0
        });        

        console.log("GOT POSITION DATA:", positionData);
        setTimeout(Testing.getPosition, (window as any).config.pullRemoteTransformFrequency);
    }

    private static getMediaID() : string{
        let prefix = SHOWBOAT.ChannelPrefixes.get(SHOWBOAT.ShowboatChannelType.Attendees);            
        return `${prefix}_${Testing.userID}`;            
    }


    private static GetQueryParam = (paramName : string) => {
        paramName = paramName.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + paramName + '=([^&#]*)');
        var results = regex.exec(window.location.search);
        return (results !== null) ? decodeURIComponent(results[1].replace(/\+/g, ' ')) : null;
    };
    
    private static setChannelClaims(){            

        SHOWBOAT.LiveSwitchClientController.channels.forEach((channel)=>{
            let channelID : string = channel.getId();
            if(channelID.startsWith(SHOWBOAT.ChannelPrefixes.get(SHOWBOAT.ShowboatChannelType.Attendees))){
                Testing.channelClaims.set(SHOWBOAT.ShowboatChannelType.Attendees, channel);
            } else if(channelID.startsWith(SHOWBOAT.ChannelPrefixes.get(SHOWBOAT.ShowboatChannelType.Backstage))){
                Testing.channelClaims.set(SHOWBOAT.ShowboatChannelType.Backstage, channel);
            } else if(channelID.startsWith(SHOWBOAT.ChannelPrefixes.get(SHOWBOAT.ShowboatChannelType.Presenter))){
                Testing.channelClaims.set(SHOWBOAT.ShowboatChannelType.Presenter, channel);
            }
        });
        
    }


    private static createSpawnAreas(){

        let totalArea : number = 0;
        for(let i=0; i < (window as any).config.spawnAreas.length; ++i){
            let rectData : any = (window as any).config.spawnAreas[i];
            rectData.area = rectData.plusOrMinusX * rectData.plusOrMinusZ;
            totalArea += rectData.area;
            rectData.weight = 0;
            rectData.cumulativeWeight = 0;
            Testing.randomPositionRectangles.push(rectData);
        }

        let cumulativeWeight = 0;
        for(let i = 0; i < Testing.randomPositionRectangles.length; ++i){
            let rectData : any = Testing.randomPositionRectangles[i];
            rectData.weight = rectData.area / totalArea;
            cumulativeWeight += rectData.weight;
            rectData.cumulativeWeight = cumulativeWeight;
            Testing.randomPositionRectangles[i] = rectData;

        }

        
    }

    private static getRandomPosition(){
        let val1 = Math.random();

        console.log(Testing.randomPositionRectangles);

        let rectData : any = null;
        for(let i=0; i < Testing.randomPositionRectangles.length; ++i){
            let _rectData : any = Testing.randomPositionRectangles[i];
            console.log(i, val1, _rectData);
            if(!rectData && val1 <= _rectData.cumulativeWeight){
                rectData = Testing.randomPositionRectangles[i];
                console.log(i);
                break;
            }
        }

        if(!rectData) rectData = Testing.randomPositionRectangles[Testing.randomPositionRectangles.length - 1];

        let randX = Math.random() * rectData.plusOrMinusX;
        let randXDirection = Math.random() > 0.5 ? 1:-1;

        let randZ = Math.random() * rectData.plusOrMinusZ;
        let randZDirection = Math.random() > 0.5 ? 1:-1;

        Testing.position_x = rectData.x + randX * randXDirection;
        Testing.position_y = rectData.y;
        Testing.position_z = rectData.z + randZ * randZDirection;

        Testing.rotation_x = 0;
        Testing.rotation_y = Math.PI;//Math.random() * Math.PI * 2;
        Testing.rotation_z = 0;

    }

    /*
    private static getRandomPosition(){

        let val = Math.random();

        Testing.position_x = 0;
        Testing.position_y = 0;

        Testing.rotation_x = 0;
        Testing.rotation_y = Math.PI;//Math.random() * Math.PI * 2;
        Testing.rotation_z = 0;

        if(val < 0.5){
            Testing.position_x = 0 + Math.random() * 60 - 30;
            Testing.position_z = 0 + Math.random() * 18 + 20;
        } else {
            Testing.position_x = 0 + Math.random() * 44 - 22;
            Testing.position_z = 0 + Math.random() * 25 + 38;
        }

    }
    */

    public static getCurrentPosition() : number[]{
        return  [Testing.position_x + Testing.offset_x, Testing.position_y, Testing.position_z + Testing.offset_z ]
    }

    private static drawLoop(){
        Testing.streamingAvatarCanvasContext.drawImage(
            Testing.htmlVideoElement,
            40,
            0,
            240,
            240,
            0,
            0,
            240,
            240
        );
    }

    

}