import { Stream } from "stream";
import { Testing } from "./Testing";

export class RemotePlayer {
    private downStreamController : SHOWBOAT.LiveswitchDownstreamController = null;
    public get DownStreamController() {return this.downStreamController;}

    public static DistanceUpdateInterval:number = 1000 / 4; 
    public static AudioUpdateInterval:number = 1000 / 4;
    public static LabelUpdateInterval:number = 1000 / 4;   

    private timeSinceDistanceUpdate:number = 0;
    private timeSinceAudioUpdate:number = 0;

    private static NearbyQualifyingDistance : number = SHOWBOAT.RemotePlayersZoneConfig.Distance_Nearby;
    private static NearbyUIDistance : number = SHOWBOAT.RemotePlayersZoneConfig.Distance_NearbyUI;
    private static CloseVolumeRadius: number = SHOWBOAT.RemotePlayersZoneConfig.Distance_Closeby; //Distance at which volume is 1
    private static YAudioThreshold : number = SHOWBOAT.RemotePlayersZoneConfig.deltaYAudioThreshold;

    private hasWebcamera:boolean = false;
    public get HasWebCam() {return this.hasWebcamera;}

    private hasMicrophone:boolean = false;
    public get HasMic() {return this.hasMicrophone;}

    //public localPlayerTransformNode:BABYLON.TransformNode = null;

    private latestDistanceCalculation:number = Infinity;
    public get LatestDistanceCalculation() {return this.latestDistanceCalculation;}
    public get LatestMediaAdjustedDistanceCalculation() {
        if(this.latestRemotePartition == SHOWBOAT.ShowboatChannelType.Presenter) return 0;
        else return this.latestDistanceCalculation;
    }
    
    private latestYDifference : number = 0;

    private userID : string;
    //private avatarController : AvatarController;
    private eventMute : boolean;
    private role : string;
    private latestRemotePartition : SHOWBOAT.ShowboatChannelType;
    public get Partition() {return this.latestRemotePartition;}


    private latestVideoStream : MediaStream = null;   
    private latestVideoElement : HTMLVideoElement = null;  

    private isNearby : boolean = null;
    private isNearbyUI : boolean = null;

    private isHidden : boolean = false;
    public get IsHidden() {return this.isHidden;}

    
    private initialPositionUpdated : boolean = false;

    private zoneCutoffs : Array<number> = null;

    private audienceSilenced : boolean = false;

    private spatialAudio : boolean = true;

    private myPosition : number [] = [0,0,0];

    private myInterval;

    public constructor(properties: any = {}) {

        RemotePlayer.NearbyQualifyingDistance = SHOWBOAT.RemotePlayersZoneConfig.Distance_Nearby;
        RemotePlayer.NearbyUIDistance = SHOWBOAT.RemotePlayersZoneConfig.Distance_NearbyUI;
        RemotePlayer.CloseVolumeRadius = SHOWBOAT.RemotePlayersZoneConfig.Distance_Closeby; //Distance at which volume is 1
        RemotePlayer.YAudioThreshold = SHOWBOAT.RemotePlayersZoneConfig.deltaYAudioThreshold;
       
        //this.localPlayerTransformNode = properties.localPlayerTransform;
        this.userID = properties.remotePlayerData.userID as string;
        this.eventMute = properties.eventMute as boolean;
        this.role = properties.remotePlayerData.role as string;
        this.latestRemotePartition = this.GetPartition(properties.remotePlayerData)
        this.spatialAudio = properties.spatialAudio as boolean;

        this.SetupAvatar(properties.remotePlayerData);

        let localChannel : SHOWBOAT.ShowboatChannelType = this.GetPartition(SHOWBOAT.LocalAvatarDataManager.avatarData);

        this.downStreamController = new SHOWBOAT.LiveswitchDownstreamController(
            properties.remotePlayerData.userID, 
            this.latestRemotePartition, 
            SHOWBOAT.RemoteStreamingMode.Disconnected, 
            properties.remotePlayerData.cameraEnabled, 
            properties.remotePlayerData.micEnabled, 
            this.eventMute, 
            properties.silenceAudience,
            localChannel,
            this.OnCameraFeedAvailable.bind(this),
            properties.remotePlayerData.firstName + 
            " " + 
            properties.remotePlayerData.lastName + 
            " " + 
            this.userID);
            
        this.SetHidden();

      
        this.myInterval = setInterval(
            ()=>{
                this.UpdateMedia()
            }, 100);
    }

    private SetupAvatar(remotePlayerData : SHOWBOAT.AvatarData){
        this.hasWebcamera = remotePlayerData.cameraEnabled;
        this.hasMicrophone = remotePlayerData.micEnabled;

        //avatarController.setColor(remotePlayerData.color);
        //avatarController.setNameLabel(remotePlayerData.firstName, remotePlayerData.lastName);    
        //avatarController.setTitleLabel(remotePlayerData.company);
        //avatarController.setFace(remotePlayerData.face);

        this.updateLaserUsage(remotePlayerData.laserEnabled);

        this.SetHidden();
    }

   

    private OnCameraFeedAvailable(isAvailable : boolean, videoElement : HTMLVideoElement, videoStream : MediaStream){

        console.log("OnCameraFeedAvailable",this.hasWebcamera, isAvailable, videoElement, videoStream);

        if(this.hasWebcamera) {
            /*
            if(videoElement && this.latestVideoElement != videoElement) {
                this.latestVideoElement = videoElement;
                this.avatarController.setWebCamTexture(this.latestVideoElement);
            }
            */
            
            if(videoStream && this.latestVideoStream != videoStream) {
                this.latestVideoStream = videoStream;
                //this.avatarController.setWebcamTextureMediaStream(this.latestVideoStream);
            }
            
            if(isAvailable === true){
                //this.avatarController.showWebCamera();
                this.addHTMLPreview();                                                      //FIX THIS.... Its causing extra camera views to appear!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            }
            else{
                //this.avatarController.hideWebCamera();
                this.removeHTMLPreview();
            }
        }
    }

    private addHTMLPreview(){
        let videoEl = document.createElement("video");
        videoEl.id = this.userID;
        videoEl.srcObject = this.latestVideoStream;
        videoEl.play();

        let container : HTMLElement = document.getElementById("remotePlayerContainer");
        container.appendChild(videoEl);
    }

    private removeHTMLPreview(){

        let videoEl = document.getElementById(this.userID);        
        if(videoEl){
            let container : HTMLElement = document.getElementById("remotePlayerContainer");
            container.removeChild(videoEl);
        }
        
    }

    private UpdateLatestDistanceCalculation() {

        let localPosition = Testing.getCurrentPosition();

        this.latestDistanceCalculation = Math.sqrt(Math.pow(localPosition[0] - this.myPosition[0], 2) + Math.pow(localPosition[1] - this.myPosition[1], 2) + Math.pow(localPosition[2] - this.myPosition[2], 2));
        

        this.latestYDifference = Math.abs(Testing.getCurrentPosition()[1] - this.myPosition[1]);

        if (this.latestDistanceCalculation <= RemotePlayer.NearbyQualifyingDistance)
        {
            if(this.isNearby != true){
                this.isNearby = true;
                //this.avatarController.showLabels();
            }
        }
        else
        { 
            if(this.isNearby != false){
                this.isNearby = false;
                //this.avatarController.hideLabels();
            }
        }

        /*
        if (this.latestDistanceCalculation <= RemotePlayer.NearbyUIDistance)
        {
            if(this.isNearbyUI != true){
                this.isNearbyUI = true;
                this.JoinNearbyList(this.userID);
            }
        }
        else
        { 
            if(this.isNearbyUI != false){
                this.isNearbyUI = false;
                this.LeaveNearbyList(this.userID);
            }
        }
        */
    }

    private UpdateMedia = () => {
        if(this.isHidden) return;

        const deltaTime = 0.1 * 1000;
        this.timeSinceDistanceUpdate += deltaTime;
        //this.timeSinceWebcamUpdate += deltaTime;
        this.timeSinceAudioUpdate += deltaTime;

        // Distance
        if(this.timeSinceDistanceUpdate >= RemotePlayer.DistanceUpdateInterval){
            this.timeSinceDistanceUpdate = 0;

            this.UpdateLatestDistanceCalculation();
        }

        // Volume
        if(this.timeSinceAudioUpdate >= RemotePlayer.AudioUpdateInterval) {
            this.timeSinceAudioUpdate = 0;

            let volume = 0;  

            if(this.hasMicrophone === false || this.isHidden) volume = 0;

            else if(!this.spatialAudio) volume = 1;

            else if(this.latestRemotePartition === SHOWBOAT.ShowboatChannelType.Presenter) volume = 1;

            else if(this.latestYDifference > RemotePlayer.YAudioThreshold) volume = 0;   

            else {                           
                if (this.latestDistanceCalculation > RemotePlayer.NearbyQualifyingDistance) {
                    volume = 0;
                } else if (this.latestDistanceCalculation <= RemotePlayer.CloseVolumeRadius) {
                    volume = 1;
                } else {
                    const adjustedDistance = this.latestDistanceCalculation - RemotePlayer.CloseVolumeRadius;
                    volume = 1 - (adjustedDistance / (RemotePlayer.NearbyQualifyingDistance - RemotePlayer.CloseVolumeRadius));
                    volume *= volume;
                }
            }

            if(this.downStreamController) this.downStreamController.volumeLevel = volume;
        }
    }

    public SetHidden() {
        this.isHidden = true;
        //this.avatarController.setInvisible();
        
        //if(this.downStreamController) this.downStreamController.volumeLevel = 0;
    }

    public SetUnhidden(){
        this.isHidden = false;
        //this.avatarController.setVisible();

        this.UpdateLatestDistanceCalculation();
    }

    public getPosition() : number[]{
        return this.myPosition
    }

    public getLookDirection() : number[]{
        return [0,0,0];
    }
    
    public setPosition(x:number, y:number, z:number) 
    {       
        this.myPosition = [x,y,z];        

        this.downStreamController.setPosition(x, y, z);

        if(this.initialPositionUpdated == false)
        { 
            this.initialPositionUpdated = true;
            this.SetAvatarVisibility(this.GetPartition(SHOWBOAT.LocalAvatarDataManager.avatarData), this.latestRemotePartition);
        }
    }

    public setRotation(x:number, y:number, z:number) {
        //this.avatarController.setRotation(x, y, z);

        this.downStreamController.setOrientation(x, y, z);
    }

    public recalculateLaser(){
        //this.avatarController.recalculateLaser();
    }

    public updateCameraUsage(usingCam : boolean){
        console.warn("updateCameraUsage", usingCam);
        this.hasWebcamera = usingCam;

        if(this.hasWebcamera === false)
        { 
            //this.avatarController.detachWebCam();     
            //this.avatarController.hideWebCamera();
            this.removeHTMLPreview();
            this.latestVideoStream = null;
        }

        this.downStreamController.setCamera(this.hasWebcamera);

        //this.avatarController.FaceDarkenedForWebcam = this.hasWebcamera;
    }

    public updateMicUsage(usingMic : boolean){
        this.hasMicrophone = usingMic;
        //if(this.hasMicrophone === false && this.downStreamController) this.downStreamController.volumeLevel = 0;
        this.downStreamController.setMicrophone(usingMic);
    }

    public updateLaserUsage(usingLaser : boolean){
        //if (usingLaser) this.avatarController.showLaser();
        //else if(!usingLaser) this.avatarController.hideLaser(); 
    }

  

    public SetMediaConnected(connected : boolean){
        if(this.downStreamController){
            if(this.latestRemotePartition === SHOWBOAT.ShowboatChannelType.Presenter){
                this.downStreamController.streamingMode = SHOWBOAT.RemoteStreamingMode.Connected;
                //if(this.hasWebcamera) this.avatarController.FaceDarkenedForWebcam = true;
            }
            else
            {
                if(connected) {
                    this.downStreamController.streamingMode = SHOWBOAT.RemoteStreamingMode.Connected;
                    //if(this.hasWebcamera) this.avatarController.FaceDarkenedForWebcam = true;
                }
                else if(!connected) { 
                    if(this.hasWebcamera){
                        //this.avatarController.detachWebCam();     
                        //this.avatarController.hideWebCamera();
                        this.removeHTMLPreview();
                        this.latestVideoStream = null;
                    }
                    this.downStreamController.streamingMode = SHOWBOAT.RemoteStreamingMode.Disconnected;
                }
            }
        }
    }

    public SetEventMute(eventMute: boolean){
        if(this.downStreamController) this.downStreamController.eventMute = eventMute;
    }

    public SetSilenceAudience(silenceAudience: boolean){
        if(typeof silenceAudience === 'string') silenceAudience = silenceAudience === "true" ? true : false;
        if(this.downStreamController) this.downStreamController.silenceAudience = silenceAudience;
    }

    public SetSpatialAudio(value: boolean){
        if(typeof value === 'string') value = value === "true" ? true : false;
        this.spatialAudio = value;
    }

    public UpdateLocalPlayerChannel(partitionString: string){
        if(this.downStreamController) { 
            let localChannel : SHOWBOAT.ShowboatChannelType = this.GetPartitionFromString(partitionString);
            this.downStreamController.myChannelType = localChannel;

            this.SetAvatarVisibility(localChannel, this.latestRemotePartition);
        }
    }

    public UpdateRemotePlayerChannel(partitionString : string){
        if(this.downStreamController){
            this.latestRemotePartition = this.GetPartitionFromString(partitionString);
            this.downStreamController.remoteAvatarChannelType = this.latestRemotePartition;

            let localChannel : SHOWBOAT.ShowboatChannelType = this.GetPartition(SHOWBOAT.LocalAvatarDataManager.avatarData);

            this.SetAvatarVisibility(localChannel, this.latestRemotePartition);
        }
    }

    public updateGrounded(grounded: boolean) {
        //this.avatarController.SetGlowVisibility(grounded);
    }

    private SetAvatarVisibility(localChannel: SHOWBOAT.ShowboatChannelType, remoteChannel: SHOWBOAT.ShowboatChannelType) {     
        if(localChannel != null && remoteChannel != null)
        {
            switch (localChannel) {
                case SHOWBOAT.ShowboatChannelType.Attendees:
                    switch (remoteChannel) {
                        case SHOWBOAT.ShowboatChannelType.Attendees:
                            this.SetUnhidden();
                            break;
                        case SHOWBOAT.ShowboatChannelType.Backstage:
                            this.SetHidden();
                            break;
                        case SHOWBOAT.ShowboatChannelType.Presenter:
                            this.SetUnhidden();
                            break;
                        default:
                            SHOWBOAT.Logger.Error("cannot find remote channel");
                            return;
                    }
                    break;
                case SHOWBOAT.ShowboatChannelType.Backstage:
                    switch (remoteChannel) {
                        case SHOWBOAT.ShowboatChannelType.Attendees:
                            this.SetHidden();
                            break;
                        case SHOWBOAT.ShowboatChannelType.Backstage:
                            this.SetUnhidden();
                            break;
                        case SHOWBOAT.ShowboatChannelType.Presenter:
                            this.SetUnhidden();
                            break;
                        default:
                            SHOWBOAT.Logger.Error("cannot find remote channel");
                            return;
                    }
                    break;
                case SHOWBOAT.ShowboatChannelType.Presenter:
                    switch (remoteChannel) {
                        case SHOWBOAT.ShowboatChannelType.Attendees:
                            this.SetUnhidden();
                            break;
                        case SHOWBOAT.ShowboatChannelType.Backstage:
                            this.SetHidden();
                            break;
                        case SHOWBOAT.ShowboatChannelType.Presenter:
                            this.SetUnhidden();
                            break;
                        default:
                            SHOWBOAT.Logger.Error("cannot find remote channel");
                            return;
                    }
                    break;
                default:
                    SHOWBOAT.Logger.Error("cannot find local channel");
                    return;
            }
        }
    }

    public GetPartition(avatarData : SHOWBOAT.AvatarData) : SHOWBOAT.ShowboatChannelType {

        if (avatarData.isPresenting) {
            return SHOWBOAT.ShowboatChannelType.Presenter
        } 

        if (avatarData.roomID === "backstage") {
            return SHOWBOAT.ShowboatChannelType.Backstage
        }

        return SHOWBOAT.ShowboatChannelType.Attendees;
    }

    public GetPartitionFromString(partitionString: string) : SHOWBOAT.ShowboatChannelType {
        switch(partitionString){
            case "attendees":
                return SHOWBOAT.ShowboatChannelType.Attendees;
            case "presenter":
                return SHOWBOAT.ShowboatChannelType.Presenter;
            case "backstage":
                return SHOWBOAT.ShowboatChannelType.Backstage;
            default:
                SHOWBOAT.Logger.Error("Failed to find partition based on given string: " + partitionString);
                return SHOWBOAT.ShowboatChannelType.Attendees;
        }
    }

    private JoinNearbyList = (userID : string) => {
        SHOWBOAT.UIEventManager.OnJoinedNearbyList.Raise(userID);
    }

    private LeaveNearbyList = (userID : string) => {
        SHOWBOAT.UIEventManager.OnLeftNearbyList.Raise(userID);
    }

    public dispose(){
        //this.scene.unregisterBeforeRender(this.UpdateMedia);
        clearInterval(this.myInterval);
        this.removeHTMLPreview();
        if(this.downStreamController)
        {
            this.downStreamController.destroy();
            this.downStreamController = null;
        }    
        if(this.isNearby) this.LeaveNearbyList(this.userID);
    }
}
