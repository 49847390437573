import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//If we are in dev environment, increase logging level
if (process.env.REACT_APP_API_STAGE === "dev") {
  SHOWBOAT.Logger.ConsoleLogLevel = SHOWBOAT.LogLevel.Debug;
  SHOWBOAT.Logger.WebLogLevel = SHOWBOAT.LogLevel.None;
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
