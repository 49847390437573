import React, { useEffect } from 'react';
import './App.css';
import { Testing } from './Testing';
import { TestingAudio } from './TestingAudio';

function App() {

  useEffect(() => {
    Testing.Init();
  }, []);


  



  return (
    <div className="App">      
      <p>
        Testing
      </p>

      <video id="sbvideo" src="" autoPlay loop/>
      <button id="startButton">Join test</button>

      <div id="container">

      </div>
      <div id="remotePlayerContainer"></div>
       
    </div>
  );
}

export default App;
